import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { ErrorManagementService } from "../services/error-management.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorManagementService: ErrorManagementService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          const errorResponse: HttpErrorResponse = err;
          if (errorResponse.error && errorResponse.status !== 401) {
            this.errorManagementService.handleException(errorResponse.error);
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
