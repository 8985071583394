import { Component, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { AVAILABLE_LOCALES, LanguageService } from "src/app/services/language.service";

@Component({
  selector: "app-language-switcher",
  templateUrl: "./language-switcher.component.html",
  styleUrls: ["./language-switcher.component.scss"],
})
export class LanguageSwitcherComponent implements OnDestroy {
  public languageList: string[] = AVAILABLE_LOCALES;
  public locale?: string;

  /**
   * if ngUnsubscribe$ emits a value (null) --> terminate all running subscriptions inside the service
   */
  private ngUnsubscribe$: Subject<null> = new Subject();

  constructor(private languageService: LanguageService) {
    this.languageService
      .getUserLocale$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((locale) => (this.locale = locale));
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  public onChangeLanguage(locale: string) {
    if (locale) {
      this.languageService.setUserLocale(locale);
    }
  }
}
