export const LOCAL_STORAGE_AUTH_DATA = "auth_data";
export const ENVIRONMENT_INJECT_TOKEN = "inject:environment";
export const LOCAL_STORAGE_LOCALE_DATA = "user_locale";
export const DEFAULT_LOCALE = "de";
export const EMPTY_STRING = "";

/**
 * LaunchPad Permissions
 */
export const APP_PERMISSION_FRONTEND_ADMIN = "frontend_admin";
