export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/?login=true";
export const PROFILE_ROUTE = "/settings/profile";
export const LAUNCHPAD_ROUTE = "/apps";
export const SAP_SYSTEMS_APP_ROUTE = LAUNCHPAD_ROUTE + "/sap-systems";
export const SAP_SYSTEMS_APP_ISSUE_PROCESSES_ROUTE = LAUNCHPAD_ROUTE + "/sap-processes";
export const SAP_SYSTEMS_APP_USER_MANAGEMENT_ROUTE = LAUNCHPAD_ROUTE + "/users";
export const SILVER_MARBLES_APP_ROUTE = LAUNCHPAD_ROUTE + "/silver-marbles";
export const SILVER_MARBLES_APP_ACTION_CHECK_USER_EXISTS = SILVER_MARBLES_APP_ROUTE + "/check-user-exists";
export const SILVER_MARBLES_APP_ACTION_RFC_READ_TABLE = SILVER_MARBLES_APP_ROUTE + "/rfc-read-table";
export const SILVER_MARBLES_APP_ACTION_CHECK_ROLE_EXISTS = SILVER_MARBLES_APP_ROUTE + "/check-role-exists";
export const SILVER_MARBLES_APP_ACTION_CHECK_ROLE_ASSIGNED = SILVER_MARBLES_APP_ROUTE + "/check-role-assigned";
export const SILVER_MARBLES_APP_ACTION_FIND_ROLES_BY_TCODE = SILVER_MARBLES_APP_ROUTE + "/find-roles-by-tcode";
