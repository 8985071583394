import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AsyncSubject, Observable, catchError, firstValueFrom, switchMap, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenEndpointsPath = "auth/token/";
  private i18nPartialPath = "i18n/";
  private newToken = new AsyncSubject<string>();
  private requestingTimer?: ReturnType<typeof setTimeout>;

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isTokenEndpoint = request.url.includes(this.tokenEndpointsPath);
    const isI18nEndpoint = request.url.includes(this.i18nPartialPath);

    if (isI18nEndpoint) {
      return next.handle(request);
    }

    // Get the auth token from the service.
    const token = this.authService.getAccessToken();

    if (!token && !isTokenEndpoint) {
      return this.renewTokenAndRequest(request, next);
    }
    request = this.addToken(request, token);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !isTokenEndpoint) {
          return this.renewTokenAndRequest(request, next);
        } else {
          // Other error, pass it through
          return throwError(() => error);
        }
      }),
    );
  }

  private renewTokenAndRequest(request: HttpRequest<unknown>, next: HttpHandler) {
    // Unauthorized, try refreshing the token and retry the request
    this.debounceTokenRequestSchedule();
    return this.newToken.pipe(
      switchMap((newToken) => {
        const newRequest = this.addToken(request, newToken);
        return next.handle(newRequest);
      }),
    );
  }

  private debounceTokenRequestSchedule() {
    clearTimeout(this.requestingTimer);
    this.requestingTimer = setTimeout(async () => {
      const newToken = await firstValueFrom(this.authService.refreshToken()).catch(() => {
        // Refresh token failed, logout the user
        this.authService.logout();
      });
      if (newToken) {
        this.newToken.next(newToken);
        this.newToken.complete();
      }
      this.newToken.unsubscribe();
    }, 1000);
  }

  private addToken(request: HttpRequest<unknown>, token?: string): HttpRequest<unknown> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return request;
    }
  }
}
